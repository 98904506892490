@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Inter', sans-serif;
  }
}

@layer components {
  body {
    background-color: rgb(249, 249, 249);
  }
}

.customScroll {
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f4;
    border-radius: 6px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #dbdfe9;
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #dbdfe9;
  }
}

.transparentScrollBar {
  ::-webkit-scrollbar {
    height: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: #f1f5f9;
    border-radius: 6px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #cbd5e1;
    border-radius: 6px;
    background-clip: content-box;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #cbd5e1;
    border-radius: 6px;
  }
}

.slick-slide > div {
  margin: 0px 5px;
}

.lineBackground {
  background-image: repeating-linear-gradient(
    135deg,
    rgb(250, 250, 250),
    rgb(250, 250, 250) 2px,
    rgb(255, 255, 255) 2px,
    rgb(255, 255, 255) 8px
  );
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
